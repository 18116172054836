import React from 'react';
import { Route, Routes } from 'react-router';
import { NPS } from './components/nps';
import { Question } from './components/question';
import { Email } from './components/email';

const App = () => {
  return (
    <Routes>
      <Route path="/nps" element={<NPS />} />
      <Route path="/question" element={<Question />} />
      <Route path="/email" element={<Email />} />
    </Routes>
  );
};

export const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

export default App;
