import React from 'react';
import { getQueryVariable } from '../App';

export const Email = () => {

    return (
        <>
            <iframe
                id="typeform-full"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="camera; microphone; autoplay; encrypted-media;"
                src={`https://form.typeform.com/to/vsG9HR6b?typeform-medium=embed-snippet&uniqueid=${getQueryVariable('uniqueid')}&storeid=${getQueryVariable('storeid')}&country=${getQueryVariable('country')}`}
            >
            </iframe>
        </>
    );
};
