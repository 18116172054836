import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const baseUrl = document.querySelector('base')?.getAttribute('href') || '/';
const rootElement = document.getElementById('root');

// Correct usage of createRoot
createRoot(rootElement).render(
  <React.StrictMode>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
