import React from 'react';
import { getQueryVariable } from '../App';

export const Question = () => {

    return (
        <>
            <iframe
                id="typeform-full"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="camera; microphone; autoplay; encrypted-media;"
                src={`https://form.typeform.com/to/omfc7LRp?typeform-medium=embed-snippet&uniqueid=${getQueryVariable('uniqueid')}&storeid=${getQueryVariable('storeid')}&nps=${getQueryVariable('nps')}&country=${getQueryVariable('country')}`}
            >
            </iframe>

        </>
    );
};
