import React, { useState } from 'react';
import { getQueryVariable } from '../App';

export const NPS = () => {

    const [uuid] = useState(crypto.randomUUID());

    const getLanguage = () => {
        return window.navigator.userLanguage || window.navigator.language;
    };

    const getStoreData = () => {
        const checkpleaseMap = {
            "wMQntXdWS2": "I001",
            "NuObLc2Er7": "I002",
            "ITF5rECex1": "I003",
            "hquADg9r2k": "I004",
            "8eylL7ke4T": "I005",
            "keavsIDFmu": "I006",
            "cC9pda257j": "I007",
            "r0FccIOdja": "I008",
            "RVDEtbcefA": "I009",
            "no8JQcPZsB": "I010",
            "lFYugOakAv": "I011",
            "cLI7koxccN": "I012",
            "WUpw9tyw9O": "I013",
            "T7xN3qJgP9": "I015",
            "3TqY0Ivn8J": "I016",
            "xkxAwETygr": "U001",
            "sfTMJriVO1": "U002",
            "sfTMJriVO2": "I017",
            "W8Dsjnm1V4kA": "I018",
            "TQrr2Tnqrm": "I019",
            "mZgsNJJ3vZ": "I020",
        };

        const cps = getQueryVariable('cps');
        if (cps && cps.length > 0) {
            return checkpleaseMap[cps];
        } else {
            return getQueryVariable('storeid');
        }
    };

    return (
        <>
            <iframe
                id="typeform-full"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="camera; microphone; autoplay; encrypted-media;"
                src={`https://form.typeform.com/to/fE8l6SF4?typeform-medium=embed-snippet&uniqueid=${uuid}&storeid=${getStoreData()}&country=${getQueryVariable('country')}&language=${getLanguage()}&cpt=${getQueryVariable('cpt')}&cps=${getQueryVariable('cps')}`}
            >
            </iframe>
        </>
    );
};
